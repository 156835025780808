<template>
  <div>
    <!-- 头部 -->
    <mHeader></mHeader>

    <div class="box">
      <div class="neir">
        <div class="tit">404</div>
        <p>Sorry,Page Not Find.</p>
        <el-button @click="btnClick">Back to home</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import mHeader from "@/components/m_header.vue";
export default {
  methods: {
    btnClick() {
      this.$router.push({
        path: "/",
      });
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_404") {
        this.$router.replace("/m_404");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/404") {
        this.$router.replace("/404");
      }
    }
  },
  components: {
    mHeader,
  },
};
</script>

<style lang="less" scoped>
.box {
  margin: 32px auto 0;
  .neir {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #12334c;
    .tit {
      font-size: 33.0667vw;
      font-weight: bold;
    }
    p {
      font-size: 5.3333vw;
      font-weight: bold;
      margin-bottom: 11.2vw;
    }
    .el-button {
      width: 38.6667vw;
      // height: 42px;
      padding: 3.2vw 0;
      border-radius: 7px;
      background: #12334c;
      color: #fff;
      font-size: 4.2667vw;
    }
  }
}
</style>